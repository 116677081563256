<template>
	<v-container class="mt-4 ml-2 pr-6" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<!-- TITULO -->
	  		  <v-card-title class="text-subtitle-1">
	  		    Seguimiento a alumnos
	  		    <v-spacer></v-spacer>

		        <v-btn 
		          class="mr-2" 
		          small dark color="green"
		          @click="exportExcel( alumnos  , 'SEGUIMIENTO_NI')"
		          tile
		        >
		          <v-icon small>mdi-microsoft-excel</v-icon>
		        </v-btn>

		        <v-btn 
		          v-if="getdatosUsuario.idpuesto != 18"
		          class="mr-2" 
		          small dark color="red"
		          tile
		          @click="dialogRepote = true"
		        >
		          <v-icon left small>mdi-file-document-multiple</v-icon>
		          Reporte
		        </v-btn>

		        <v-btn
		          color="primary"
		          dark
		          class="text-capitalize"
		          @click="initialize()"
		          small
		          tile
		        >
		          <v-icon left small>mdi-refresh</v-icon>
		          Actualizar
		        </v-btn>

	  		  </v-card-title>
	  		  
	  		  <!-- Datos generales como las tablas -->
	  		  <v-card-text>
	  		  	<v-row justify="space-between">

	  		  		<!-- SELECCIONAR EL CICLO -->
	  		  		<v-col cols="12" md="4" lg="3" class="pb-0">
                <v-autocomplete
	                filled
	                dense
	                clearable
	                v-model="ciclo"
	                :items="ciclos"
	                label="Selecciona ciclo actual"
	                persistent-hint
	                hide-details
	                return-object
	                item-text="ciclo"
	                item-value="id_ciclo"
	              >
	              </v-autocomplete>
              </v-col>

              <!-- BUSCAR LOS DATOS -->
	  		  		<v-col cols="12" md="6" lg="5">
	  		  			<v-text-field
				          label="Buscar"
				          v-model="search"
				          dense
				          filled
				          append-icon="mdi-magnify"
				        ></v-text-field>
	  		  		</v-col>

	  		  	</v-row>


	  		  	<div class="text-h6">
		          <b>Total: </b> {{ alumnos.length }}
		          <br/>
		          <b>Sin seguimiento: </b> {{ alumnos.filter( el => { return el.seguimiento_whatsApp > 3 }).length }}
		          <br/>
		          <br/>
		        </div>

	  		  	<!-- TABLA -->
					  <v-data-table
					    :headers="headers"
					    :items="alumnos"
					    class="elevation-0"
					    :search="search"
					    dense
					    multi-sort
					  >

					  	<template v-slot:item.escuela="{ item }">
                <v-chip 
                	small 
                	:color="item.escuela ==1 ? '#2EDAD5' : '#000D4A' "
                	:dark="item.escuela ==1 ? false : true "
                >
                	{{ item.escuela ==1 ? 'INBI' : 'FAST' }}
                </v-chip>
              </template>

              <template v-slot:item.conmutador="{ item }">
		            <!-- chip para prospectos atrasados -->
		            <v-tooltip bottom :color=" item.conmutador.length ? 'green' : 'red' " >
		              <template v-slot:activator="{ on, attrs }">
		                <v-chip 
		                	small 
		                	:color=" item.conmutador.length ? 'green' : 'red' "
		                	dark 
		                	v-bind="attrs" 
		                	v-on="on" 
		                >
		                  {{ item.conmutador.length ? 'SI' : 'NO' }}
		                </v-chip>
		              </template>
		              <span>
		              	LLamadas > 3 min: {{ item.mastresmin }}
										<br/>
										contestadas:      {{ item.contestadas }}
										<br/>
										rechazadas:       {{ item.rechazadas }}
										<br/>
										ocupadas:         {{ item.ocupadas }}
										<br/>
										fallidas:         {{ item.fallidas }}
		              </span>

		            </v-tooltip>
		          </template>

		          <template v-slot:item.seguimiento_acceso_plataforma="{ item }">
		          	<v-tooltip bottom :color="item.seguimiento_acceso_plataforma == 1 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.seguimiento_acceso_plataforma == 1 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.seguimiento_acceso_plataforma == 1 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span>{{ 'El alumno accedió el día: ' + item.ultimo_movimiento + '.' }} </span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.seguimiento_whatsApp="{ item }">
		          	<v-tooltip bottom 
		          		:color="item.seguimiento_whatsApp > 3 || !item.configurado || !item.ultimo_mensaje ? 'red' : 'green' "
		          	>
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.seguimiento_whatsApp > 3 || !item.configurado || !item.ultimo_mensaje ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.seguimiento_whatsApp > 3 || !item.configurado || !item.ultimo_mensaje ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>

						      <span v-if="item.seguimiento_whatsApp > 3 && item.ultimo_mensaje">
						      	Al alumno no se le ha brindado ningúna información por whatsApp desde hace {{ item.seguimiento_whatsApp }} días
						      </span>

						      <span v-if="item.seguimiento_whatsApp <= 3 && item.ultimo_mensaje ">
						      	Seguimiento correcto del alumno
						      </span>

						      <br v-if="!item.configurado"/>
						      <span v-if="!item.configurado"> El usuario no esta configurado </span>
						      
						      <br/>
						      <span>
						      	{{ item.ultimo_mensaje ? 'último mensaje: ' + item.ultimo_mensaje : 'No tiene mensajes'}}
						      </span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.participacion="{ item }">
		          	<v-tooltip bottom :color="item.participacion == 1 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.participacion == 1 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.participacion == 1 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span>{{ 'El alumno lleva: ' + item.dias_sin_participar + ' días sin participar.'}}</span>
						    </v-tooltip>
		          </template>


		          <template v-slot:item.ejercicios="{ item }">
		          	<v-tooltip bottom :color="item.ejercicios == 1 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.ejercicios == 1 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.ejercicios == 1 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span>{{ 'Al alumno le faltan: ' + item.faltantes + ' ejercicios.'}}</span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.datos_vacios="{ item }">
		          	<v-tooltip bottom :color="item.datos_vacios == 1 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.datos_vacios == 1 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.datos_vacios == 1 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span v-if="item.ultimo_movimiento == 'SIN DATO'">
						      	{{ 'El alumno accedió el día: ' + item.ultimo_movimiento + '.' }}
						      </span>
						      <br/>
						      <span v-if="item.participaciones">
						      	{{ 'El alumno no tiene registros de participación, hablar con el teacher.'}}
						      </span>
						      <br/>
						      <span v-if="item.faltantes == 'TODOS'">
						      	{{ 'El alumno no ha realizado ningún ejercicio.'}}
						      </span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.adeudo="{ item }">
		          	<v-tooltip bottom :color="item.adeudo > 0 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.adeudo > 0 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.adeudo > 0 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span>
						      	{{ item.adeudo }}
						      </span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.recibos_enviados="{ item }">
		          	<v-tooltip bottom :color="item.recibos_enviados == 0 ? 'red' : 'green' ">
						      <template v-slot:activator="{ on, attrs }">
				            <v-chip
		                	:color="item.recibos_enviados == 0 ? 'red' : 'green' "
		                	dark
				              dark
				              small
						          v-bind="attrs"
						          v-on="on"
				            >
		                	{{ item.recibos_enviados == 0 ? 'NO' : 'SI' }}
				            </v-chip>
						      </template>
						      <span>{{ 'El alumno ha recibido: ' + item.recibos_enviados + ' veces su recibo' }} </span>
						    </v-tooltip>
		          </template>

		          <template v-slot:item.actualizar="{ item }">
		          	<v-chip color="primary" small dark @click="abrirDialogEdita = true, alumno = item, nuevoTelefono = item.telefono">Actualizar</v-chip>
		          </template>

					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
  		</v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <v-dialog
	    v-model="abrirDialogEdita"
	    persistent
	    max-width="350"
	  >
			<v-card>
				<!-- PAGO OFICIAL -->
				<v-card-title class="mt-12">
					<span class="text-body-1"><b>Actualizar WhatsApp</b></span>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text v-if="alumno">
			  	<v-text-field
         	  label="WhatsApp"
         	  hide-details
         	  type="number"
         	  v-model="nuevoTelefono"
         	  single-line
         	  outlined
         	  dense
         	></v-text-field>
				</v-card-text>
				
		    <v-card-actions>
		      <v-btn
		        @click="abrirDialogEdita = false"
		        rounded
		        color="red"
		        dark
		        class="text-capitalize"
		      >
		        Cancelar
		      </v-btn>
		      <v-spacer></v-spacer>
		      <v-btn
		        @click="actualizarWhatsApp()"
		        rounded
		        color="green"
		        dark
		        class="text-capitalize"
		      >
		        Listo
		      </v-btn>
		    </v-card-actions>
			</v-card>

			<!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
	    <carga                v-if="cargar"/>

		</v-dialog>


		<v-dialog
	    v-model="dialogRepote"
	    persistent
	    max-width="600"
	  >
			<v-card>
				<!-- PAGO OFICIAL -->
				<v-card-title>
					<span class="text-body-1"><b>Reporte seguimiento</b></span>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text >
					<v-subheader class="pl-0 black--text">FAST</v-subheader>
			  	<v-data-table
				    :headers="headersReporte"
				    :items="reporteFast"
				    class="elevation-0"
				    dense
				    hide-default-footer
				  >
				  </v-data-table>

				  <v-divider class="mt-6"></v-divider>

					<v-subheader class="pl-0 black--text">INBI</v-subheader>
				  <v-data-table
				    :headers="headersReporte"
				    :items="reporteInbi"
				    class="elevation-0"
				    dense
				    hide-default-footer
				  >
				  </v-data-table>

				</v-card-text>
		    <v-card-actions>
		      <v-btn
		        @click="dialogRepote = false"
		        rounded
		        color="red"
		        dark
		        class="text-capitalize"
		      >
		        Cancelar
		      </v-btn>
		      <v-spacer></v-spacer>
		    </v-card-actions>
			</v-card>

			<!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
	    <carga                v-if="cargar"/>

		</v-dialog>

	</v-container>
</template>

<script>
import axios from 'axios';
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activeEjercicios: true,
      ejercicios:[],

      activeFaltas: false,

      dialogEstatus:false,
      alumnoEstatus:{},
      tipoEstatus: 0,
      mensaje:'',
      solucion:'',

    	search:'',
      dialog: false,

      headers: [
        { text: 'Matricula'    , value: 'matricula'                     },
        { text: 'Alumno'       , value: 'nombre'                        },
        { text: 'Grupo'        , value: 'grupo'                         },
        { text: 'Conmutador'   , value: 'conmutador'                    },
        { text: 'Plataforma'   , value: 'seguimiento_acceso_plataforma' },
        { text: 'WhatsApp'     , value: 'seguimiento_whatsApp'          },
        { text: 'Participación', value: 'participacion'                 },
        { text: 'Ejercicios'   , value: 'ejercicios'                    },
        { text: 'Datos vacios' , value: 'datos_vacios'                  },
        { text: 'Adeudo'       , value: 'adeudo'                        },
        { text: 'Recibo'       , value: 'recibos_enviados'              },
        { text: 'Actions'      , value: 'actualizar'                    },
      ],

      headersReporte: [
        { text: 'Plantel'          , value: 'plantel'        },
        { text: 'Sin seguimeinto'  , value: 'sinseguimiento' },
        { text: 'Total'            , value: 'contador'       },
      ],

      alumnos: [],
      ciclos:  [],
      ciclo:   null,
      alumno: null,
      nuevoTelefono:'',

      abrirDialogEdita:false,
      reporteFast:[],
      reporteInbi:[],
      dialogRepote: false
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch:{
      ciclo () {
        if( this.ciclo ){ this.initialize() }
      },

    	alumno( value ){
    		console.log( value )
    	}
    },


    created () {
      // Pasp 1: Obtener los ciclos
      this.getCiclos()
    },

    methods: {
      getCiclos(){
        this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Obtener la información de los laumnos en riesgo de ese ciclo
      initialize () {
        this.cargar = true

      	const payload = {
      		cicloFast: this.ciclo.id_ciclo_relacionado,
					cicloInbi: this.ciclo.id_ciclo,
      	}

        this.alumnos = []

        // Primero traemos los que faltan por entrar a la plataforma
        this.$http.post('riesgo.seguimiento.ri', payload).then(response=>{
          // Los guardamos
    			const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

        	this.alumnos = response.data.alumnosERPVIEJO.filter( el => { return planteles.includes(el.id_plantel) })

        	this.reporteFast = response.data.arregloPlantelesFast
					this.reporteInbi = response.data.arregloPlantelesINBI

          this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      actualizarWhatsApp(){
      	if( this.nuevoTelefono ){

      		this.cargar = true

	      	const payload = {
	      		escuela       : this.alumno.escuela,
	      		id_alumno     : this.alumno.id_alumno,
	      		iderp         : this.alumno.iderp,
	      		nuevoTelefono : this.nuevoTelefono
	      	}

	        // Primero traemos los que faltan por entrar a la plataforma
	        this.$http.post('whatsapp.update', payload).then(response=>{
	          // Los guardamos
	          this.nuevoTelefono    = false
	          this.abrirDialogEdita = false
	          this.alumno           = null
	        	this.initialize()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        })
      	}
      }
    },
  }
</script>
